import ProviderBase from './ProviderBase';

const   BaseEndpoint = '/scheduled-visit';

class ScheduledVisitProvider extends ProviderBase {
    constructor() {
        super(BaseEndpoint);
    }

    getOne(id, includes) {
        return this._getOne(id, includes);
    }

    getAll(filter) {
        return this._getAll(filter);
    }

    post(data) {
        return this._post(data);
    }

    put(id, data) {
        return this._put(id, data);
    }

    repair(id) {
        return this._patch(id);
    }

    delete(id) {
        return this._delete(id);
    }
}

export default ScheduledVisitProvider;
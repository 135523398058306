import React from 'react'
import {
  CButton,
  CCol,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CInvalidFeedback,
  CRow,
  CFormGroup,
  CInputCheckbox,
  CLabel,
  CSpinner,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { FormComponent } from 'src/views/prototypes';
   
class LoginForm extends FormComponent {
    constructor(props) {
        super(props);
        this.state = {
          ...this.state,
          email: "",
          password: "",
          rememberMe: false,
        };
    }   

    getValidationSchema() {
        return [
            {
                field: 'email',
                method: 'isEmpty',
                validWhen: false,
                message: 'Email is required'
              },
              {
                field: 'email',
                method: 'isEmail',
                validWhen: true,
                message: 'Please enter a valid email'
              },
              {
                field: 'password',
                method: 'isEmpty',
                validWhen: false,
                message: 'Password is required'
              }        
        ];
    }
    
    renderContent(validationState) {
        return (
            <>
                <h1>Login</h1>
                <p className="text-muted">Sign In to your account</p>
                <CInputGroup className="mb-3">
                    <CInputGroupPrepend>
                        <CInputGroupText>
                            <CIcon name="cil-user" />
                        </CInputGroupText>
                    </CInputGroupPrepend>
                    <CInput id="email" type="text" placeholder="Email" autoFocus autoComplete="email" value={this.state.email} onChange={this.handleChange} invalid={validationState.email.isInvalid}/>
                    <CInvalidFeedback>{validationState.email.message}</CInvalidFeedback>
                </CInputGroup>
                <CInputGroup className="mb-2">
                    <CInputGroupPrepend>
                        <CInputGroupText>
                            <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                    </CInputGroupPrepend>
                    <CInput type="password" id="password" placeholder="Password" autoComplete="current-password" value={this.state.password} onChange={this.handleChange} invalid={validationState.password.isInvalid}/>
                    <CInvalidFeedback>{validationState.password.message}</CInvalidFeedback>
                </CInputGroup>
                <CFormGroup variant="checkbox" className="checkbox mb-3">
                    <CInputCheckbox 
                    id="rememberMe" 
                    name="rememberMe" 
                    checked={this.state.rememberMe}
                    onChange={this.handleChange}
                    />
                    <CLabel variant="checkbox" className="form-check-label" htmlFor="rememberMe">Remember me?</CLabel>
                </CFormGroup>
                <CRow>
                    <CCol xs="6">
                        <CButton type="submit" color="primary" className="px-4" disabled={this.props.disabled}>
                            {
                                this.props.processing ? (
                                    <React.Fragment>
                                        <CSpinner component="span" size="sm" aria-hidden="true" className="mr-1"/>
                                        Logging in...
                                    </React.Fragment>
                                ) : "Login"
                            }
                        </CButton>
                    </CCol>
                    <CCol xs="6" className="text-right">
                        <CButton color="link" className="px-0" href="/#/request-password-reset">Forgot password?</CButton>
                    </CCol>
                </CRow>
            </>
        )
    }
}

export default LoginForm;

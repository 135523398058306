import React from 'react'

const Home = React.lazy(() => import('./views/home/HomePage'));
const Users = React.lazy(() => import('./views/administrators/UsersPage'));
const Employees = React.lazy(() => import('./views/employees/EmployeesPage'));
const Patients = React.lazy(() => import('./views/patients/PatientsPage'));
const Voicemails = React.lazy(() => import('./views/voicemails/VoicemailsPage'));
const TextMessages = React.lazy(() => import('./views/text_messages/TextMessagesPage'));
const Visits = React.lazy(() => import('./views/visits/VisitsPage'));
const CallLogs = React.lazy(() => import('./views/call_logs/CallLogsPage'));
const PatientBillingReports = React.lazy(() => import('./views/patient_billing_reports/PatientBillingReportsPage'));
const PayrollAuditReports = React.lazy(() => import('./views/payroll_audit_reports/PayrollAuditReportsPage'));
const PayrollReports = React.lazy(() => import('./views/payroll_reports/PayrollReportsPage'));
const VisitTypes = React.lazy(() => import('./views/visit_types/VisitTypesPage'));
const PatientActivities = React.lazy(() => import('./views/patient_activities/PatientActivitiesPage'));
const CallScript = React.lazy(() => import('./views/call_script/CallScriptPage'));
const OvertimeAuthorizations = React.lazy(() => import('./views/overtime_authorizations/OvertimeAuthorizationsPage'));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', exact: true, name: '', component: Home },
  { path: '/home', exact: true, name: '', component: Home },
  { path: '/staff/administrators', exact: true, name: 'Administrators', component: Users },
  { path: '/staff/employees', exact: true, name: 'Employees', component: Employees },
  { path: '/staff/overtime-authorizations', exact: true, name: 'Overtime Authorizations', component: OvertimeAuthorizations },
  { path: '/patients', exact: true, name: 'Patients', component: Patients },
  { path: '/messaging/voicemails', exact: true, name: 'Voicemails', component: Voicemails },
  { path: '/messaging/text-messages', exact: true, name: 'Text Messages', component: TextMessages },
  { path: '/visits', exact: true, name: 'Visits', component: Visits },
  { path: '/call-logs', exact: true, name: 'Call Logs', component: CallLogs },
  { path: '/reports/patient-billing', exact: true, name: 'Patient Billing Reports', component: PatientBillingReports },
  { path: '/reports/payroll-audit', exact: true, name: 'Payroll Audit Reports', component: PayrollAuditReports },
  { path: '/reports/payroll-report', exact: true, name: 'Payroll Reports', component: PayrollReports },
  { path: '/settings/visit-types', exact: true, name: 'Visit Types', component: VisitTypes },
  { path: '/settings/patient-activities', exact: true, name: 'Patient Activities', component: PatientActivities },
  { path: '/settings/call-script', exact: true, name: 'Call Script', component: CallScript },
]

export default routes;

import ProviderBase from './ProviderBase';

const   BaseEndpoint = '/voicemail-read-event',
        SummaryEndpoint = '/voicemail-read-event/summary';

class VoicemailReadEventProvider extends ProviderBase {
    constructor() {
        super(BaseEndpoint);
    }

    getOne(id, includes) {
        return this._getOne(id, includes);
    }

    getAll(filter) {
        return this._getAll(filter);
    }

    getSummary() {
        return this.api.get(SummaryEndpoint);
    }
}

export default VoicemailReadEventProvider;
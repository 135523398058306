import React from 'react';
import { UserProvider } from 'src/api';
import { PageComponent } from '../prototypes';
import {
    CContainer,
    CRow,
    CCol,
    CCard,
    CCardHeader,
    CCardBody,
    CCollapse,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import EditProfileForm from './EditProfileForm';
import ChangeEmailAddressForm from './ChangeEmailAddressForm';

class ProfilePage extends PageComponent {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            inEmailUpdateMode: false,
            processing: false,
            updated: false,
            profile: {},
            email: {},
        };

        this.pageWidth = 9;

        this.handleCancel = this.handleCancel.bind(this);
        this.userProvider = new UserProvider();
    }

    handleCancel() {
        this.props.history.goBack();
    }

    initialize() {
        var sessionString = sessionStorage.getItem('session');
        if (sessionString && sessionString != 'undefined') {
            var session = JSON.parse(sessionString);
            if (session) {
                this.setState({
                    profile: {
                        first: session.firstName,
                        last: session.lastName,
                        email: session.emailAddress,    
                    },
                    email: {
                        email: session.emailAddress,
                    }
                });
            }
        }

        return Promise.resolve();
    }

    handleProfileSaved(formState) {
        this.setState({processing:true});

        var userData = {
            firstName: formState.first,
            lastName: formState.last,
        };
        
        this.userProvider.patch('me', userData)
        .then(response => this.screenApiResponse(response))
        .then(response => response && response.json())
        .then(data => {
            sessionStorage.setItem('session', JSON.stringify(data));
            this.toastSuccess("Personal information has been updated");
            this.setState({updated: true});
            window.dispatchEvent(new Event('session'));        
        })
        .catch(error => this.handleError(error));
    }

    handleEmailEditModeSelected(event) {
        event.preventDefault();
        this.setState({inEmailUpdateMode: true});
    }

    handleEmailChanged(event) {
        
    }

    renderContent() {
        return (
            <>
                <h4>Edit Profile Information</h4>
                <CCard>
                    <CCardBody>
                        <CCollapse show={!this.state.inEmailUpdateMode}>
                            <CContainer>
                                <CRow>
                                    <CCol>
                                        <EditProfileForm 
                                        formName="profile" 
                                        initialValues={this.state.profile} 
                                        onSubmit={this.handleProfileSaved.bind(this)} 
                                        onCancel={this.handleCancel.bind(this)} 
                                        processing={this.state.processing}
                                        onEmailEditModeSelected={this.handleEmailEditModeSelected.bind(this)}/>
                                    </CCol>
                                </CRow>
                            </CContainer>
                        </CCollapse>
                        <CCollapse show={this.state.inEmailUpdateMode}>
                            <CContainer>
                                <CRow>
                                    <CCol>
                                        <ChangeEmailAddressForm
                                        formName="changeEmail"
                                        initialValues={this.state.email}
                                        onSubmit={this.handleEmailChanged.bind(this)}
                                        onCancel={() => this.setState({inEmailUpdateMode: false})}/>
                                    </CCol>
                                </CRow>
                            </CContainer>
                        </CCollapse>
                    </CCardBody>
                </CCard>
            </>
        );
    }
}

export default ProfilePage;

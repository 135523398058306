import React from 'react';
import PropTypes from 'prop-types';
import {
    CFormGroup,
    CInputGroup,
    CInput,
    CInputGroupAppend,
    CInputGroupPrepend,
    CInputGroupText,
    CLabel,
    CFormText,
    CInvalidFeedback,
    CRow,
    CCol,
    CButton,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { FormComponent } from '../prototypes';

const propTypes = {
    onEmailEditModeSelected: PropTypes.func,
}

class EditProfileForm extends FormComponent {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            first: '',
            last: '',
            email: '',
        };
    }

    getValidationSchema() {
        return [
            {
                field: 'first',
                method: 'isEmpty',
                validWhen: false,
                message: 'A first name is required'
              },
              {
                  field: 'last',
                  method: 'isEmpty',
                  validWhen: false,
                  message: 'A last name is required'
              },
          ];
    }

    setStateFromInitialValues(initialValues) {
        this.setState({
            first: initialValues.first ? initialValues.first : '',
            last: initialValues.last ? initialValues.last : '',
            email: initialValues.email ? initialValues.email : '',
        });
    }

    renderContent(validationState) {
        return (
            <>
                <CFormGroup>
                    <CLabel>Email</CLabel>
                    <CInputGroup controlid="email" className="mb-3">
                        <CInputGroupPrepend>
                            <CInputGroupText>
                                <CIcon name="cil-envelope-closed" />
                            </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput type="email" id="email" value={this.state.email} disabled />
                    </CInputGroup>
                    <CFormText><a href="#" onClick={this.props.onEmailEditModeSelected}>Change primary email address</a></CFormText>
                </CFormGroup>
                <CRow form>
                    <CCol md={12}>
                        <CLabel>Name</CLabel>
                    </CCol>
                </CRow>
                <CRow form>                                                        
                    <CCol md={6}>
                        <CFormGroup>                                                                
                            <CInputGroup controlid="first" className="mb-3">
                                <CInputGroupPrepend>
                                    <CInputGroupText>
                                        <CIcon name="cil-user"/>
                                    </CInputGroupText>
                                </CInputGroupPrepend>
                                <CInput type="text" id="first" placeholder="First Name" value={this.state.first} onChange={this.handleChange} invalid={validationState.first.isInvalid}/>
                                <CInvalidFeedback>{validationState.first.message}</CInvalidFeedback>
                            </CInputGroup>
                        </CFormGroup>
                    </CCol>
                    <CCol md={6}>
                        <CFormGroup>
                            <CInputGroup controlid="last" className="mb-3">
                                <CInput type="text" id="last" placeholder="Last Name" value={this.state.last} onChange={this.handleChange} invalid={validationState.last.isInvalid}/>
                                <CInvalidFeedback>{validationState.last.message}</CInvalidFeedback>
                            </CInputGroup>
                        </CFormGroup>
                    </CCol>
                </CRow>
                <hr className="my-2" />
                <CRow>
                    <CCol xs="12">
                        <CButton type="submit" color="primary" className="float-right ml-2">Save</CButton>
                        <CButton color="secondary" className="float-right" onClick={this.props.onCancel}>Close</CButton>
                    </CCol>
                </CRow>            
            </>
        )
    }
}

EditProfileForm.propTypes = {...FormComponent.propTypes, ...propTypes}

export default EditProfileForm;
import { sygnet } from './sygnet'
import { logo } from './logo'
import { logoNegative } from './logo-negative'

import {
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon,
  cilUser,
  cilSettings,
  cilLockLocked,
  cilInfo,
  cilWarning,
  cilCheckCircle,
  cilBurn,
  cilAccountLogout,
  cilCaretBottom,
  cilBusAlt,
  cilPeople,
  cilMoney,
  cilHome,
  cilBan,
  cilX,
  cilEnvelopeClosed,
  cilUserPlus,
  cilUserX,
  cilSend,
  cilDelete,
  cilAvTimer,
  cilMicrophone,
  cilPlus,
  cilMinus,
  cilTrash,
  cilCalendar,
  cilBalanceScale,
  cilPencil,
  cilCloudUpload,
  cilCreditCard,
  cilNotes,
  cilGrid,
  cibAdobeAcrobatReader,
  cilPrint,
  cilLowVision,
  cilClearAll,
  cilShare,
  cilAddressBook,
  cilCarAlt,
  cilPhone,
  cilClipboard,
  cilVoiceOverRecord,
  cilDataTransferDown,
  cilReload,
  cilBell,
} from '@coreui/icons'

export const icons = Object.assign({}, {
  sygnet,
  logo,
  logoNegative
}, {
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon,
  cilUser,
  cilSettings,
  cilLockLocked,
  cilInfo,
  cilWarning,
  cilCheckCircle,
  cilBurn,
  cilAccountLogout,
  cilCaretBottom,
  cilBusAlt,
  cilPeople,
  cilMoney,
  cilHome,
  cilBan,
  cilX,
  cilEnvelopeClosed,
  cilUserPlus,
  cilUserX,
  cilSend,
  cilDelete,
  cilAvTimer,
  cilMicrophone,
  cilPlus,
  cilMinus,
  cilTrash,
  cilCalendar,
  cilBalanceScale,
  cilPencil,
  cilCloudUpload,
  cilCreditCard,
  cilNotes,
  cilGrid,
  cibAdobeAcrobatReader,
  cilPrint,
  cilLowVision,
  cilClearAll,
  cilShare,
  cilAddressBook,
  cilCarAlt,
  cilPhone,
  cilClipboard,
  cilVoiceOverRecord,
  cilDataTransferDown,
  cilReload,
  cilBell,
})

Date.prototype.isSameDayAs = Date.prototype.f = function() {
    var d = this,
            otherDate = arguments[0];
    return d.getFullYear() === otherDate.getFullYear() &&
    d.getMonth() === otherDate.getMonth() &&
    d.getDate() === otherDate.getDate();
}

Date.prototype.roundMinutes = Date.prototype.f = function(minutes) {
    var d = this;
    var interval = minutes * 60 * 1000;
    return new Date(Math.round(d / interval) * interval);
}
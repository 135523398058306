import ProviderBase from './ProviderBase';

const   BaseEndpoint = '/daily-summary-report';

class DailySummaryReportProvider extends ProviderBase {
    constructor() {
        super(BaseEndpoint);
    }

    getSummary() {
        return this.api.get(BaseEndpoint);
    }
}

export default DailySummaryReportProvider;
import ProviderBase from './ProviderBase';

const   BaseEndpoint = '/user',
        ToggleActiveEndpoint = '/user/{0}/toggleactive',
        ZoneEndpoint = '/user/{0}/zones/{1}';

class UserProvider extends ProviderBase {    
    constructor() {
        super(BaseEndpoint);
    }

    getOne(id, includes) {
        return this._getOne(id, includes);
    }

    getAll(filter) {
        return this._getAll(filter);
    }

    put(id, data) {
        return this._put(id, data);
    }

    patch(id, data) {
        return this._patch(id, data);
    }

    delete(id) {
        return this._delete(id);
    }

    toggleActive(id) {
        return this._patch(ToggleActiveEndpoint.format(id));
    }

    grantZoneAccess(id, securityZoneId) {
        return this.api.post(ZoneEndpoint.format(id, securityZoneId));
    }

    removeZoneAccess(id, securityZoneId) {
        return this.api.delete(ZoneEndpoint.format(id, securityZoneId));
    }
}

export default UserProvider;
import React from 'react'
import {
  CBadge,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'

const TheHeaderDropdown = (props) => {
  
  const session = props.session;

  return (
    <CDropdown
      inNav
      className="c-header-nav-items mx-2"
      direction="down"
    >
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <span>{session.firstName ? (session.firstName + ' ' + session.lastName) : session.emailAddress}&nbsp;<CIcon name="cil-caret-bottom"/></span>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem href="/#/profile">
          <CIcon name="cil-user" className="mfe-2" /> Profile
        </CDropdownItem>
        <CDropdownItem href="/#/logout">
          <CIcon name="cil-account-logout" className="mfe-2" /> Logout
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default TheHeaderDropdown

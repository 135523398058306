import React from 'react';
import {
    CContainer,
    CRow,
    CCol,
    CCard,
    CCardBody,
    CCollapse,
    CButton,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { UserInvitationProvider } from 'src/api';
import queryString from 'query-string';
import { PageComponent } from '../prototypes';
import UserInvitationForm from './UserInvitationForm';

class UserInvitationPage extends PageComponent {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            formVisible: false,
            formEnabled: true,
            formProcessing: false,
            completed: false,              
        }

        this.userInvitationProvider = new UserInvitationProvider();

        this.pageWidth = 8;
    }

    initialize() {
        const qs = queryString.parse(this.props.location.search);
        if (!qs.invitationId) {
          this.showAlert("You must be here by mistake. You have not provided a confirmation token.", "info", "cil-ban");
          return Promise.resolve();
        } else {
          return this.userInvitationProvider.getInvitation(qs.invitationId, qs.studioId)
            .then(response => this.screenApiResponse(response))
            .then(response => response && response.json())
            .then(data => {
              if (!data) this.showAlert("The invitation was not found.", "warning", "cil-warning");
              else {
                if (data.status === 'Expired') this.showAlert("The invitation is no longer valid.", "warning", "cil-az-timer");
                else if (data.status === 'Confirmed') this.showAlert("This invitation has already been accepted.", "info", "cil-check-circle");
                else this.setState({formVisible: true});
              }
            })
            .catch(error => this.handleError(error))
        }  
    }

    handleSubmit(formState) {
        this.setState({formProcessing:true});
        const qs = queryString.parse(this.props.location.search);
        this.userInvitationProvider.completeInvitation(qs.invitationId, qs.studioId,
            {
                userPassword:formState.password,
                userFirstName: formState.firstName,
                userLastName: formState.lastName,                
            })
            .then(response => this.screenApiResponse(response))
            .then(data => {
                this.setState({ formVisible: false, completed: true });
                this.showAlert("Registration completed! Return to the sign-in page to login", "success");      
          })
          .catch(error => this.handleError(error));
    }

    renderContent() {
        return (
            <>
                <CCard className="p-4">
                  <CCardBody>
                    <CContainer>
                      <CRow className="justify-content-center">
                        <h2>Noteable Telephony EVV</h2>
                      </CRow>
                      <CRow>
                        <CCol>
                          <hr className="text-dark"/>
                        </CCol>
                      </CRow>
                    </CContainer>
                    <CCollapse show={this.state.formVisible}>
                        <UserInvitationForm 
                        formName="registration-form"
                        processing={this.state.formProcessing}
                        onSubmit={this.handleSubmit.bind(this)} />
                    </CCollapse>
                    <CCollapse show={this.state.completed}>
                        <CContainer>
                            <CRow>
                                <p className="text=muted">
                                    Your account is setup. Return to the sign-in page and login with your email and password.
                                </p>
                            </CRow>
                        </CContainer>
                    </CCollapse>
                  </CCardBody>
                </CCard>
                <CRow className="justify-content-center">
                    <CButton color="link" className="px-0" href="/#/login">Go to sign-in page</CButton>
                </CRow>
            </>
        );
    }
}

export default UserInvitationPage;
import React from 'react';
import PropTypes from 'prop-types';
import {
    CFormGroup,
    CInputGroup,
    CInput,
    CInputGroupAppend,
    CInputGroupPrepend,
    CInputGroupText,
    CLabel,
    CFormText,
    CInvalidFeedback,
    CRow,
    CCol,
    CButton,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { FormComponent } from '../prototypes';

class ChangeEmailAddressForm extends FormComponent {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            email: '',
            newEmail: '',
        }
    }

    getValidationSchema() {
        return [
            {
                field: 'newEmail',
                method: 'isEmpty',
                validWhen: false,
                message: 'You must enter a new email address to continue'
            },
            {
                field: 'newEmail',
                method: 'isEmail',
                validWhen: true,
                message: 'Please enter a valid email'
            },  
        ];
    }

    setStateFromInitialValues(initialValues) {
        this.setState({email: initialValues.email});
    }

    renderContent(validationState) {
        return (
            <>
                <p className="text-muted">
                    In order to change your email address, you must confirm your access to the desired new address.
                    Enter it below and click 'Confirm'. An email will be sent to that address with instructions to
                    complete the process.
                </p>                
                <CFormGroup>
                    <CLabel>Enter New Email</CLabel>
                    <CInputGroup controlid="newEmail" className="mb-3">
                        <CInputGroupPrepend>
                            <CInputGroupText>
                                <CIcon name="cil-envelope-closed" />
                            </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput type="email" id="newEmail" placeholder="Email" autoComplete="email" defaultValue={this.state.newEmail} invalid={validationState.newEmail.isInvalid}/>
                        <CInvalidFeedback>{validationState.newEmail.message}</CInvalidFeedback>
                    </CInputGroup>
                </CFormGroup>
                <hr className="my-2" />
                <CRow>
                    <CCol xs="12">
                        <CButton type="submit" color="primary" className="float-right ml-2">Confirm</CButton>
                        <CButton color="secondary" className="float-right" onClick={this.props.onCancel}>Cancel</CButton>
                    </CCol>
                </CRow>
            </>
        );
    }
}

export default ChangeEmailAddressForm;


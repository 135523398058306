import moment from "moment";

class PayrollReportExportBuilder {
    constructor(report) {
        this.reportData = report;
    }

    buildExcelReport() {
        let dataSets = [];

        let titleDataSet = {
            columns: [],
            data: [[{ value: "Visitations Payroll Data " + moment(this.reportData.fromDate).format('MM-DD-yyyy') + "-" + moment(this.reportData.toDate).format('MM-DD-yyyy'), style: { font: {sz:"24"}} }]],
        }

        dataSets.push(titleDataSet);

        let reportDataSet = {
            columns: [
                "Empl ID",
                "Empl Last Name",
                "Empl First Name",
                "Pay Code",
                "Reg",
                "Other",
                "Ovt",
            ],
            data: this.reportData.items.map(item => this.getRow(item)),
        };

        dataSets.push(reportDataSet);
        return dataSets;
    }

    getRow(item) {
        return [
            item.employeeId,
            item.employeeLastName,
            item.employeeFirstName,
            item.payCode,
            item.regularTime,
            item.otherPayable,
            item.overtime,
        ];
    }
}

export default PayrollReportExportBuilder;
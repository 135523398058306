const Settings = {
    BaseUrl: process.env.REACT_APP_URL,
    ApiBaseUrl: process.env.REACT_APP_API_URL + "/api",
    ApiKey: process.env.REACT_APP_API_KEY,
    DefaultRoute: '/',
    ArrivalWindow: 30,
    DepartureWindow: 30,
}

export default class Config {
    static getSetting(key) {
        return Settings[key];
    }

    static get BaseUrl() {
        return Config.getSetting('BaseUrl');
    }

    static get ApiBaseUrl() {
        return Config.getSetting('ApiBaseUrl');
    }

    static get ApiKey() {
        return Config.getSetting('ApiKey');
    }

    static get DefaultRoute() {
        return Config.getSetting('DefaultRoute');
    }

    static get ArrivalWindow() {
        return Config.getSetting('ArrivalWindow');
    }

    static get DepartureWindow() {
        return Config.getSetting('DepartureWindow');
    }
}
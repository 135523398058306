import ProviderBase from './ProviderBase';

const   BaseEndpoint = '/patient-activity';

class PatientActivityProvider extends ProviderBase {
    constructor() {
        super(BaseEndpoint);
    }

    getOne(id, includes) {
        return this._getOne(id, includes);
    }

    getAll(filter) {
        return this._getAll(filter);
    }

    post(data) {
        return this._post(data);
    }

    put(id, data) {
        return this._put(id, data);
    }

    patch(id, data) {
        return this._patch(id, data);
    }

    delete(id) {
        return this._delete(id);
    }
}

export default PatientActivityProvider;
import React from 'react';
import {
    CForm,
    CRow,
    CCol,
    CButton,
    CSpinner,
  } from '@coreui/react'
  
import PropTypes from 'prop-types';
import { FormValidator } from 'src/tools';
import moment from 'moment';

const propTypes = {
    formName: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    processing: PropTypes.bool,
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    inline: PropTypes.bool,
}

class FormComponent extends React.Component {    
    constructor(props) {
        super(props);

        this.validator = new FormValidator(this.getValidationSchema());

        this.state = {
            validation: this.validator.valid(),
        }

        this.submitted = false;

        this.handleChange = this.handleChange.bind(this);
        this.handleNumericInputChange = this.handleNumericInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        // settings that can be overridden by child classes
        this.showButtonPanel = false;
    }    

    componentDidMount() {
        this.setStateFromInitialValues(this.props.initialValues);
    }

    // abstract methods - must be overridden in child class
    getValidationSchema() {
        return [];
    }

    // virtual methods - can be overridden in child class
    setStateFromInitialValues() {
        // null op. child class can implement.
    }

    clearStateOnSubmit() {
        // child class can implement
    }

    handleChange = (event) => {
        if (event.target.type == 'checkbox') {
            this.setState({
                [event.target.id]: event.target.checked
            });
        } else {
            this.setState({
                [event.target.id]: event.target.value
            });      
        }
    }

    handleNumericInputChange(valueAsNumber, valueAsString, eventTarget) {
        this.setState({
            [eventTarget.id]: valueAsNumber
        });
    }

    // abstract methods
    renderContent(validationState) {
        return (
            <>You must override the renderContent method on the base class.</>
        )
    }

    handleSubmit(e) {
        e.preventDefault();
        const validation = this.validator.validate(this.state);
        this.setState({validation})
        this.submitted = true;
        if (validation.isValid) {
            this.props.onSubmit(this.state);
            this.submitted = false;
            this.setState({validation: this.validator.valid()});
            this.clearStateOnSubmit();
        }
    }

    render() {
        let validationState = this.submitted ? this.validator.validate(this.state) : this.state.validation;
        return (
            <CForm inline={this.props.inline} name={this.props.formName} noValidate className={this.props.className} onSubmit={this.handleSubmit} disabled={this.props.disabled}>
                {this.renderContent(validationState)}
                {
                    this.showButtonPanel ? (
                        <>
                            <hr className="my-2" />
                            <CRow>
                                <CCol xs="12">
                                    <CButton type="submit" color="primary" className="float-right ml-2">
                                        {
                                            this.props.processing ? (
                                                <React.Fragment>
                                                    <CSpinner component="span" size="sm" aria-hidden="true" className="mr-1"/>
                                                    Saving...
                                                </React.Fragment>
                                            ) : "Save"
                                        }
                                    </CButton>
                                    <CButton color="secondary" className="float-right" onClick={this.props.onCancel}>Close</CButton>
                                </CCol>
                            </CRow>            
                        </>
                    ) : <></>
                }
            </CForm>    
        )
    }
}

FormComponent.propTypes = propTypes;

export default FormComponent;
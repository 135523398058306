import ProviderBase from './ProviderBase';

const   BaseEndpoint = '/patient-visit',
        ValidateEndpoint = '/patient-visit/{0}/validate';

class PatientVisitProvider extends ProviderBase {
    constructor() {
        super(BaseEndpoint);
    }

    getOne(id, includes) {
        return this._getOne(id, includes);
    }

    validateOne(id) {
        return this.api.get(ValidateEndpoint.format(id));
    }

    getAll(filter) {
        return this._getAll(filter);
    }

    post(data) {
        return this._post(data);
    }

    put(id, data) {
        return this._put(id, data);
    }
}

export default PatientVisitProvider;
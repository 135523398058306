import React from 'react';
import {
    CContainer,
    CRow,
    CSpinner,
} from '@coreui/react'
import PropTypes from 'prop-types';

const propTypes = {
    className: PropTypes.string,
    onError: PropTypes.func,
    onSuccess: PropTypes.func,
    onAlert: PropTypes.func,
};

class ContentComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            loadingMessage: "Loading...",
        };

        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.initialize();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    initialize() {
        // called on componentDidMount. child class should implement if needed.
    }

    screenApiResponse(response) {
        if (response.status != 200 && response.status != 201) {
            // bad request or something unexpected.
            throw (response.status + " - " + response.statusText);
        } else {
            return response;
        }
    }

    isLoading() {
        return this.state.loading;
    }

    startLoading(message) {
        if (!message) message = "Loading...";
        return this.setState({loading: true, loadingMessage: message});
    }

    stopLoading() {
        this.setState({loading: false});
    }    

    handleError(error) {
        if (this.props.onError) this.props.onError(error);
    }

    renderLoading(loadingMessage) {
        return (
            <CContainer>
                <CRow className="justify-content-center mb-1">
                    <CSpinner component="span" size="lg" aria-hidden="true"/>
                </CRow>
                <CRow className="justify-content-center">
                    <p>{loadingMessage ? loadingMessage : this.state.loadingMessage}</p>
                </CRow>
            </CContainer>      
        );
    }

    renderContent() {
        return (
            <div>
                You must override the renderContent method on the base class.
            </div>
        )
    }

    render() {
        if (this.state.loading) return this.renderLoading();
        else {
            return (
                <CContainer className={this.props.className}>
                    { this.renderContent() }
                </CContainer>
            )
        }
    }
}

ContentComponent.propTypes = propTypes;

export default ContentComponent;

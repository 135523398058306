import React from 'react';
import {
    Button,
    CCard,
    CCardBody,
    CContainer,
    CRow,
    CCol,
} from '@coreui/react';
import logo from '../../assets/logo.png';
import { PageComponent } from '../prototypes';
import RequestPasswordResetForm from './RequestPasswordResetForm';
import { AuthProvider } from 'src/api';

const logoStyle = {
    height: '80px',
    textAlign: 'center',
    marginBottom: '15px'
}

class RequestPasswordResetPage extends PageComponent {
    constructor(props) {
        super(props);
    
        this.state = {
          ...this.state,
          formDisabled: false,
          formProcessing: false,
        }
    
        // page size override
        this.pageWidth = 9;
        // className override
        this.className = "c-app c-default-layout flex-row align-items-center";
    
        this.authProvider = new AuthProvider();
    }

    handleSubmit(formState) {

    }
    
    renderContent() {
        return (
            <>
                <CContainer>
                    <CRow className="justify-content-center">
                        <img src={logo} style={logoStyle} alt="Logo" />
                    </CRow>
                    <CRow className="justify-content-center">
                        <h3>Telephony EVV Portal for JFST</h3>
                    </CRow>                    
                </CContainer>
                <hr className="text-secondary"/>
                <CCard color="white" className="p-4">
                    <CCardBody>
                        <RequestPasswordResetForm
                        disabled={this.state.formDisabled} 
                        processing={this.state.formProcessing} 
                        onSubmit={this.handleSubmit.bind(this)}/>
                    </CCardBody>
                </CCard>
            </>
        )
    }
}

export default RequestPasswordResetPage;
import moment from "moment";

class EmployeeReportExportBuilder {
    constructor(report) {
        this.reportData = report;
    }

    buildExcelReport() {
        let dataSets = [];

        let titleDataSet = {
            columns: [],
            data: [[{ value: "EVV Employee Data", style: { font: {sz:"24"}} }]],
        }

        dataSets.push(titleDataSet);

        let reportDataSet = {
            columns: [
                "Employee ID",
                "Employee Last Name",
                "Employee First Name",
                "Discipline",
                "Phone",
            ],
            data: this.reportData ? this.reportData.map(item => this.getRow(item)) : [],
        };

        dataSets.push(reportDataSet);
        return dataSets;
    }

    getRow(item) {
        return [
            item.employeeId,
            item.lastName,
            item.firstName,
            item.employeeType,
            item.smsPhoneNumber,
        ];
    }
}

export default EmployeeReportExportBuilder;
import ProviderBase from './ProviderBase';

const   BaseEndpoint = '/userinvitation';

class UserInvitationProvider extends ProviderBase {    
    constructor() {
        super(BaseEndpoint);
    }

    createInvitation(data) {
        return this._post(data);
    }

    getInvitation(id, studioId) {
        return this._getOne(id, '', 'studioId=' + studioId);
    }

    completeInvitation(id, studioId, invitation) {
        return this._patch(id, invitation, 'studioId=' + studioId);
    }

    getAll(filter, includes) {
        return this._getAll(filter, includes);
    }

    delete(id) {
        return this._delete(id);
    }
}

export default UserInvitationProvider;
import ProviderBase from './ProviderBase';

const   BaseEndpoint = '/voicemail',
        CancelEndpoint = "/voicemail/{0}/cancel",
        ReadReportEndpoint = '/voicemail/{0}/read-report';

class VoicemailProvider extends ProviderBase {
    constructor() {
        super(BaseEndpoint);
    }

    post(data) {
        return this._post(data);
    }

    getOne(id, includes) {
        return this._getOne(id, includes);
    }

    getAll(filter) {
        return this._getAll(filter);
    }

    delete(id) {
        return this._delete(id);
    }

    cancel(id) {
        return this.api.patch(CancelEndpoint.format(id));
    }

    getReadReport(id) {
        return this.api.get(ReadReportEndpoint.format(id));
    }
}

export default VoicemailProvider;
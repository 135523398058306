import React from 'react';
import {
    CModal,
    CModalHeader,
    CModalBody,
    CContainer,
    CRow,
    CCol,
    CAlert,
    CSpinner,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import PropTypes from 'prop-types';

const propTypes = {
    size: PropTypes.string,
    show: PropTypes.bool,
    className: PropTypes.string,
    processing: PropTypes.bool,
    onOpened: PropTypes.func, // hook for when the modal has been opened
    onClose: PropTypes.func, // hook for when modal is closed due to click outside
    onCancel: PropTypes.func // hook for when modal is closed due to Cancel button pressed,
}

class ModalComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            alertIcon: '',
            alertVisible: false,
            alertMessage: '',
            alertColor: "info",
            loading: false,
            loadingMessage: "Loading...",
        };

        this._isMounted = false;

        // these default settings can be overridden by the base class
        this.modalTitle = '';
    }

    componentDidMount() {
        this._isMounted = true;
        this.initialize();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    // virtual methods
    initialize() {
        // called on componentDidMount. child class should implement if needed.
    }

    handleOpened() {
        // called when modal is opened. child class should implement if needed.
    }
      
    showAlert(message, color, icon) {        
        this.setState({ alertVisible: true, alertMessage: message, alertColor: color, alertIcon: icon });
    }

    hideAlert() {
        this.setState({ alertVisible: false, alertMessage: '', alertIcon: '' });
    }

    isLoading() {
        return this.state.loading;
    }

    startLoading(message) {
        if (!message) message = "Loading...";
        return this.setState({loading: true, loadingMessage: message});
    }

    stopLoading() {
        this.setState({loading: false});
    }    

    screenApiResponse(response, errorOn404) {
        if (response.status !== 200 && response.status !== 201) {
            // bad request or something unexpected.
            throw (response.status + " - " + response.statusText);
        } else {
            this.hideAlert();
            return response;
        }
    }

    handleError(error) {
        console.log(error);
        this.showAlert("Something went wrong: " + error, "danger", "cil-burn");
        if (this.isLoading) this.stopLoading();
    }

    doOpen() {
        // child class may have implemented
        this.handleOpened(); 
        // parent component may want to take action as well
        if (this.props.onOpened) this.props.onOpened();
    }

    renderLoading(loadingMessage) {
        return (
            <CContainer>
                <CRow className="justify-content-center mb-1">
                    <CCol>
                        <CSpinner component="span" size="lg" aria-hidden="true"/>
                    </CCol>
                </CRow>
                <CRow className="justify-content-center">
                    <CCol>
                        <p>{loadingMessage ? loadingMessage : this.state.loadingMessage}</p>
                    </CCol>
                </CRow>
            </CContainer>      
        );
    }

    renderContent() {
        return (
            <></>
        )
    }

    render() {
        if (!this.props.show) return (<></>)
        return (
            <CModal size={this.props.size} show={this.props.show} onOpened={this.doOpen.bind(this)} onClose={this.props.onClose} className={this.props.className}>
                <CModalHeader className="font-weight-bold">{this.modalTitle}</CModalHeader>
                <CModalBody>
                    <CAlert className="d-flex align-items-center" color={this.state.alertColor} show={this.state.alertVisible} closeButton>
                        <CIcon name={this.state.alertIcon} className="flex-shrink-0 mr-2"/>
                        <div>
                            {this.state.alertMessage}
                        </div>
                    </CAlert>
                    {
                        this.state.loading ? this.renderLoading() : this.renderContent()
                    }
                </CModalBody>
            </CModal>
        )
    }
}

ModalComponent.propTypes = propTypes;

export default ModalComponent;

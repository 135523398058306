import React from 'react';
import {
    CContainer,
    CRow,
    CCol,
    CAlert,
    CSpinner,
    CToaster,
    CToast,
    CToastHeader,
    CToastBody,
    CButton,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import cookie from 'react-cookies';

const toastStyle = {
    zIndex: 1999
};

class PageComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            alertIcon: '',
            alertVisible: false,
            alertMessage: '',
            alertColor: "info",
            loading: false,
            loadingMessage: "Loading...",
            toastVisible: false,
            toastColor: '',
            toastMessage: '',
        };

        this._isMounted = false;

        // these default settings can be overridden by the base class
        this.pageTitle = '';
        this.pageWidth = 12;
        this.className = "c-app c-default-layout p-4";
        this.showCloseButton = false;
        this.onClose = () => {}
    }

    componentDidMount() {
        this._isMounted = true;
        this.startLoading();
        this.initialize().finally(() => this.stopLoading());
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    // virtual methods
    initialize() {
        // called on componentDidMount. child class should implement if needed.
        return Promise.resolve();
    }

    isReady() {
        // called on render. components can use this to prevent rendering after all initial promises
        // have returned but while setState has not completed.
        return true;
    }

    goBack() {
        this.props.history.goBack();
    }
      
    showAlert(message, color, icon) {        
        this.setState({ alertVisible: true, alertMessage: message, alertColor: color, alertIcon: icon });
    }

    hideAlert() {
        this.setState({ alertVisible: false, alertMessage: '', alertIcon: '' });
    }

    tryNavigate(path) {
        if (this.props.history) this.props.history.push(path);
    }

    toast(message, color) {
        this.setState({toastVisible: true, toastMessage: message, toastColor: color});
    }
    
    toastDanger(message) {
        this.toast(message, 'danger');
    }

    toastSuccess(message) {
        this.toast(message, 'success');
    }

    toastSecondary(message) {
        this.toast(message, 'secondary');
    }

    toastInfo(message) {
        this.toast(message, 'info');
    }

    toastWarn(message) {
        this.toast(message, 'warning');
    }

    toastClear() {
        this.setState({toastVisible: false, toastMessage: '', toastColor: ''});
    }

    isLoading() {
        return this.state.loading;
    }

    startLoading(message) {
        if (!message) message = "Loading...";
        return this.setState({loading: true, loadingMessage: message});
    }

    stopLoading() {
        this.setState({loading: false});
    }    

    screenApiResponse(response, errorOn404) {
        if (response.status === 401) {
            // session on server is toast. need to sign in.
            cookie.remove('token');
            sessionStorage.removeItem('session');
            this.tryNavigate('/signin');
            return response;
        }
        else if (response.status === 404 && errorOn404) {
            this.showAlert(response.status + " - " + response.statusText, "warning", "cil-warning");
        }
        else if (response.status === 400) {
            this.showAlert(response.status + " - " + response.statusText, "danger", "cil-ban");
        }
        else if (response.status === 500) {
            response.json().then(data => {
                if (data.exceptionMessage) {
                    throw data.exceptionMessage;
                } else {
                    throw response.statusText;
                }
            });
        }
        else if (response.status != 200 && response.status != 201) {
            // bad request or something unexpected.
            throw (response.status + " - " + response.statusText);
        } else {
            this.hideAlert();
            return response;
        }
    }

    handleError(error) {
        console.log(error);
        this.showAlert("Something went wrong: " + error, "danger", "cil-burn");
    }

    renderLoading(loadingMessage) {
        return (
            <CContainer>
                <CRow className="justify-content-center mb-1">
                    <CSpinner component="span" size="lg" aria-hidden="true"/>
                </CRow>
                <CRow className="justify-content-center">
                    <p>{loadingMessage ? loadingMessage : this.state.loadingMessage}</p>
                </CRow>
            </CContainer>      
        );
    }

    renderContent() {
        return (
            <div>
                You must override the renderContent method on the base class.
            </div>
        )
    }

    render() {
        if (this.state.loading || !this.isReady()) return this.renderLoading();
        else {
            return (
                <div className={this.className}>
                    <CToaster position="top-right" key="page-toaster">
                        <CToast autohide={3000} show={this.state.toastVisible} color={this.state.toastColor}>
                            <div className="d-flex">
                                <CToastHeader closeButton={true}></CToastHeader>
                                <CToastBody>{this.state.toastMessage}</CToastBody>
                            </div>
                        </CToast>
                    </CToaster>
                    <CContainer>
                        {
                            this.pageTitle ? (
                                <CRow>
                                    <CCol>
                                        <div className="d-flex">
                                            <div><h3>{this.pageTitle}</h3></div>
                                            {
                                                this.showCloseButton ? (
                                                    <div className="ml-auto">
                                                        <CButton color="dark" variant="ghost" onClick={this.onClose.bind(this)}>
                                                            <CIcon name="cil-x"/>
                                                        </CButton>
                                                    </div>
                                                ) : ''
                                            }
                                        </div>
                                        <hr className="text-light"/>
                                    </CCol>
                                </CRow>    
                            ) : (<></>)
                        }
                        <CRow className="justify-content-center">
                            <CCol md={this.pageWidth}>
                                <CAlert className="d-flex align-items-center" color={this.state.alertColor} show={this.state.alertVisible} closeButton>
                                    <CIcon name={this.state.alertIcon} className="flex-shrink-0 mr-2"/>
                                    <div>
                                        {this.state.alertMessage}
                                    </div>
                                </CAlert>
                            </CCol>
                        </CRow>
                        <CRow className="justify-content-center">
                            <CCol md={this.pageWidth}>
                                {this.renderContent()}
                            </CCol>
                        </CRow>
                    </CContainer>
              </div>
            )
        }
    }
}

export default PageComponent;

import ProviderBase from './ProviderBase';

const   BaseEndpoint = '/evv-alert';

class EvvAlertProvider extends ProviderBase {
    constructor() {
        super(BaseEndpoint);
    }

    getAll(filter) {
        return this._getAll(filter);
    }
}

export default EvvAlertProvider;
import SessionUtils from "src/tools/SessionUtils"

const _nav = [
  {
    _tag: 'CSidebarNavItem',
    name: 'Home',
    to: '/home',
    icon: 'cil-home',
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Staff',
    to: '/staff',
    icon: 'cil-people',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Administrators',
        to: '/staff/administrators',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Employees',
        to: '/staff/employees',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Overtime Auth.',
        to: '/staff/overtime-authorizations'
      },
    ]
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Patients',
    to: '/patients',
    icon: 'cil-address-book',
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Messaging',
    to: '/messaging',
    icon: 'cil-voice-over-record',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Voicemails',
        to: '/messaging/voicemails',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Text Messages',
        to: '/messaging/text-messages',
      },
    ]
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Visits',
    to: '/visits',
    icon: 'cil-car-alt',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Call Logs',
    to: '/call-logs',
    icon: 'cil-phone',
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Reports',
    route: '/reports',
    icon: 'cil-clipboard',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Patient Billing',
        to: '/reports/patient-billing',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Empl. Payroll Audit',
        to: '/reports/payroll-audit',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Payroll Report',
        to: '/reports/payroll-report',
      },
    ]
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Settings',
    route: '/settings',
    icon: 'cil-settings',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Visit Types',
        to: '/settings/visit-types'
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Patient Activities',
        to: '/settings/patient-activities'
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Call Script',
        to: '/settings/call-script',
      },
    ]
  }
]

export default _nav

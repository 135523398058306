import React from 'react';
import cookie from 'react-cookies';
import { PageComponent } from 'src/views/prototypes';
import { AuthProvider } from 'src/api';
import {
    CCard,
    CCardBody,
    CCol,
    CContainer,
    CRow,
    CButton,
  } from '@coreui/react';
  
class LogoutPage extends PageComponent {
    constructor(props) {
        super(props);   

        this.state = {
            ...this.state,
            headingText: "Signing you out...",
            buttonText: "Please wait",
            buttonEnabled: false
        }

        this.authProvider = new AuthProvider();

        this.pageWidth = 9;
        this.className = "c-app c-default-layout flex-row align-items-center";
    }

    componentDidMount() {
        var token = cookie.load('token');
        if (token && token != 'undefined') {
            this.authProvider.delete()
            .then(response => {
                if (response && response.ok) {
                    cookie.remove('token');
                    sessionStorage.removeItem('session');
                    this.setState({buttonEnabled: true, buttonText: "Sign in again", headingText: "You have been signed out."});            
                } else {
                    this.showAlert("We were unable to sign you out: " + response.status + " - " + response.statusText, "danger", "cil-account-logout");
                    this.setState({buttonEnabled: true, buttonText: "Continue", headingText: ":( Something went wrong."});            
                }
            })
            .catch(error => {
                this.showAlert("An error occurred while trying to sign you out. " + error, "danger", "cil-burn");
            })
        } else {
            this.setState({buttonEnabled: true, buttonText: "Sign in again", headingText: "You have been signed out."});
        }
    }
   
    renderContent() {
        return (
            <CCard className="p-4">
                <CCardBody>
                    <CContainer>
                        <CRow className="justify-content-center mb-3">
                            <CCol>
                                <h2>{this.state.headingText}</h2>
                            </CCol>
                        </CRow>
                        <CRow className="justify-content-center">
                            <CCol>
                                <CButton color="primary" className="px-4" href="#/login" disabled={!this.state.buttonEnabled}>{this.state.buttonText}</CButton>
                            </CCol>
                        </CRow>
                    </CContainer>
                </CCardBody>
            </CCard>
        );
    }
  }

  export default LogoutPage;
  
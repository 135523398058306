import ProviderBase from './ProviderBase';
import moment from 'moment';

const   BaseEndpoint = '/patient-billing-report?fromDate={0}&toDate={1}';

class PatientBillingReportProvider extends ProviderBase {
    constructor() {
        super(BaseEndpoint);
    }

    getReport(startDate, endDate, patientId) {
        let url = BaseEndpoint.format(moment(startDate).format('MM-DD-yyyy'), moment(endDate).format('MM-DD-yyyy'));
        if (patientId) url += '&patientId=' + patientId;
        return this.api.get(url);
    }
}

export default PatientBillingReportProvider;
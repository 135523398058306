import React, { Component } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import './scss/style.scss';
import cookie from 'react-cookies';
import TheLayout from './containers/TheLayout';

//Pages
import LoginPage from './views/login/LoginPage';
import LogoutPage from './views/logout/LogoutPage';
import ProfilePage from './views/profile/ProfilePage';
import UserInvitationPage from './views/user_invitation/UserInvitationPage';
import RequestPasswordResetPage from './views/request_password_reset/RequestPasswordResetPage';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const isAuthenticated = () => {
  var token = cookie.load('token');
  if (token) {
    const session = JSON.parse(sessionStorage.getItem('session'));
    if (session) {
      // keep the session alive. the server is doing the same thing when api calls
      // are made.
      var expire = new Date();
      expire.setTime(expire.getTime()+(30*60*1000));
      cookie.save('token', token, {path: '/', expires: expire});
      return true;
    }
  }
  
  return false;
}

const AuthRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    !isAuthenticated() 
      ? <Component {...props}/> 
      : <Redirect to='/tours' />
  )} />
);

const ProtectedRoute = ({ component: Component, path: path, ...rest }) => (
  <Route path={path} {...rest} render={(props) => { 
    return (
      isAuthenticated() 
        ? <Component {...props}/> 
        : <Redirect to={{ pathname: '/login', state: {requestedPath: props.location.pathname} }}/>
    )
  }} />
);

class App extends Component {
  render() {
    return (
      <HashRouter>
          <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/logout" name="Sign Out" component={LogoutPage} />
            <Route exact path="/profile" name="Profile" component={ProfilePage} />
            <Route exact path="/invitation" name="User Confirmation" component={UserInvitationPage} />
            <Route exact path="/request-password-reset" name="Request Password Reset" component={RequestPasswordResetPage} />
            <AuthRoute exact path="/login" name="Sign In" component={LoginPage} />                    
            <ProtectedRoute path="/" name="" component={TheLayout} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;

import Api from './Api';

class ProviderBase {
    constructor(baseEndpoint) {
        this.api = new Api();
        this.baseEndpoint = baseEndpoint;
    }

    _getOne(id, includes, queryString) {
        if (!this.baseEndpoint) throw this.endpointNotSet('base');
        var uri = this.buildGetOneUrl(this.baseEndpoint + '/' + id, includes, queryString);
        return this.api.get(uri);
    }

    _getAll(filter, includes, queryString) {
        if (!this.baseEndpoint) throw this.endpointNotSet('base');
        if (filter) filter = encodeURIComponent(filter);
        var uri = this.buildGetManyUrl(this.baseEndpoint, includes, filter, queryString);
        return this.api.get(uri);
    }

    _post(data, queryString) {
        if (!this.baseEndpoint) throw this.endpointNotSet('base');
        var uri = this.baseEndpoint;
        if (queryString) uri += '?' + queryString;
        return this.api.post(uri, JSON.stringify(data));
    }

    _put(id, data, queryString) {
        if (!this.baseEndpoint) throw this.endpointNotSet('base');
        var uri = this.baseEndpoint + '/' + id;
        if (queryString) uri += '?' + queryString;
        return this.api.put(uri, JSON.stringify(data));
    }

    _patch(id, data, queryString) {
        if (!this.baseEndpoint) throw this.endpointNotSet('base');
        var uri = this.baseEndpoint + '/' + id;
        if (queryString) uri += '?' + queryString;
        return this.api.patch(uri, JSON.stringify(data));
    }

    _delete(id, queryString) {
        if (!this.baseEndpoint) throw this.endpointNotSet('base');
        var uri = this.baseEndpoint + '/' + id;
        if (queryString) uri += '?' + queryString;
        return this.api.delete(uri);
    }

    getOne(id, includes, queryString) {
        throw this.notImplemented();
    }

    getAll(filter, queryString) {
        throw this.notImplemented();
    }

    post(data, queryString) {
        throw this.notImplemented();
    }

    put(id, data, queryString) {
        throw this.notImplemented();
    }

    patch(id, data, queryString) {
        throw this.notImplemented();
    }

    delete(id, queryString) {
        throw this.notImplemented();
    }

    endpointNotSet(endpointType) {
        return "The {0} endpoint is not set".format(endpointType);
    }    

    notImplemented() {
        return "method not implemented";
    }

    buildGetOneUrl(baseUrl, includes, queryString) {
        var uri = baseUrl;
        if (includes && includes.length > 0) {
            uri += '?include=' + includes[0];
            for (var i=1;i<includes.length;i++) {
                uri += '&include=' + includes[i];
            }
            if (queryString) uri += '&' + queryString;
        } else {
            if (queryString) uri += '?' + queryString;
        }
        return uri;
    }

    buildGetManyUrl(baseUrl, includes, filter, queryString) {
        var args = '';
        if (includes && includes.length > 0) {
            args += '?include=';
            args += includes.join('&include=');
        }
        if (filter) {
            args += (args.length > 0 ? '&' : '?');
            args += 'filter=' + filter;
        }
        if (queryString) {
            args += (args.length > 0 ? '&' : '?');
            args += queryString;
        }
        return baseUrl + args;        
    }
}

export default ProviderBase;
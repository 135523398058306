import ProviderBase from './ProviderBase';

const BaseEndpoint = '/authorization/token';

class AuthProvider extends ProviderBase {
    constructor() {
        super(BaseEndpoint);
    }

    post(credential) {
        return this._post(credential);
    }

    delete() {
        return this.api.delete(BaseEndpoint);
    }
}

export default AuthProvider;
import ProviderBase from './ProviderBase';
import moment from 'moment';

const   BaseEndpoint = '/payroll-report?fromDate={0}&toDate={1}';

class PayrollReportProvider extends ProviderBase {
    constructor() {
        super(BaseEndpoint);
    }

    getReport(startDate, endDate) {
        return this.api.get(BaseEndpoint.format(moment(startDate).format('MM-DD-yyyy'), moment(endDate).format('MM-DD-yyyy')));
    }
}

export default PayrollReportProvider;
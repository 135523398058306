import React from 'react'
import {
  CButton,
  CCol,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CInvalidFeedback,
  CRow,
  CFormGroup,
  CInputCheckbox,
  CLabel,
  CSpinner,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { FormComponent } from 'src/views/prototypes';
   
class RequestPasswordResetForm extends FormComponent {
    constructor(props) {
        super(props);
        this.state = {
          ...this.state,
          email: '',
        };
    }   

    getValidationSchema() {
        return [
            {
                field: 'email',
                method: 'isEmpty',
                validWhen: false,
                message: 'Email is required'
              },
        ];
    }
    
    renderContent(validationState) {
        return (
            <>
                <h1>Request Password Reset</h1>
                <p className="text-muted">Verify access to your email in order to reset your password. Enter the email address that you use to log in below.</p>
                <CInputGroup className="mb-3">
                    <CInputGroupPrepend>
                        <CInputGroupText>
                            <CIcon name="cil-envelope-closed" />
                        </CInputGroupText>
                    </CInputGroupPrepend>
                    <CInput id="email" type="text" placeholder="Email" autoFocus autoComplete="email" value={this.state.email} onChange={this.handleChange} invalid={validationState.email.isInvalid}/>
                    <CInvalidFeedback>{validationState.email.message}</CInvalidFeedback>
                </CInputGroup>
                <CRow>
                    <CCol>
                        <CButton type="submit" color="primary" className="px-4" disabled={this.props.disabled}>
                            {
                                this.props.processing ? (
                                    <React.Fragment>
                                        <CSpinner component="span" size="sm" aria-hidden="true" className="mr-1"/>
                                        Sending...
                                    </React.Fragment>
                                ) : "Send"
                            }
                        </CButton>
                        <CButton color="link" href="/#/login" className="float-right">Back to Login</CButton>
                    </CCol>
                </CRow>
            </>
        )
    }
}

export default RequestPasswordResetForm;

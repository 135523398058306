import React from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import {
  TheContent,
  TheSidebar,
  TheFooter,
  TheHeader
} from './index'
import IdleTimer from 'react-idle-timer';
import cookie from 'react-cookies';

const TheLayout = () => {
  let idleTimer = null;

  const session = JSON.parse(sessionStorage.getItem('session'));
  
  const onAction = () => {
    // keep the session alive. the server is doing the same thing when api calls
    // are made.
    let token = cookie.load('token');
    if (token) {
      let expire = new Date();
      expire.setTime(expire.getTime()+(30*60*1000));
      cookie.save('token', token, {path: '/', expires: expire});  
    }
  }

  const onActive = () => {
  }

  const onIdle = () => {
    cookie.remove('token');
  }

  // const darkMode = useSelector(state => state.darkMode)
  const classes = classNames(
    'c-app c-default-layout'//,
    // darkMode && 'c-dark-theme'
  )

  return session ? (
    <div className={classes}>
      <IdleTimer
        ref={ref => { idleTimer = ref}}
        element={document}
        onActive={onActive}
        onIdle={onIdle}
        onAction={onAction}
        debounce={250}
        timeout={30*60*1000} />
      <TheSidebar/>
      <div className="c-wrapper">
        <TheHeader session={session}/>
        <div className="c-body">
          <TheContent/>
        </div>
        <TheFooter/>
      </div>
    </div>
  ) : <></>
}

export default TheLayout

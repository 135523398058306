import moment from "moment";
import humanizeDuration from 'humanize-duration';

class PatientBillingReportExportBuilder {
    constructor(report) {
        this.reportData = report;
    }

    buildExcelReport() {
        let dataSets = [];

        let titleDataSet = {
            columns: [],
            data: [[{ value: "Visitations Patient Billing Data " + moment(this.reportData.fromDate).format('MM-DD-yyyy') + "-" + moment(this.reportData.toDate).format('MM-DD-yyyy'), style: { font: {sz:"24"}} }]],
        }
        dataSets.push(titleDataSet);

        let reportDataSet = {
            columns: [
                "Patient ID",
                "Patient Name",
                "Employee ID",
                "Employee Name",
                "Date",
                "Time In",
                "Time Out",
                "Duration",
                "Errand Miles",
                "Bill Code",
            ],
            data: this.buildDataSet(this.reportData),
        };

        dataSets.push(reportDataSet);

        return dataSets;
    }

    buildDataSet(reportData) {
        let dataSet = [];
        reportData.patients.forEach(p => {
            const firstRow = [
                p.patientId,
                p.patientLastName + ', ' + p.patientFirstName,
                p.items[0].employeeId,
                p.items[0].employeeLastName + ', ' + p.items[0].employeeFirstName,
                moment(p.items[0].startDateTime).format('MM/DD/yyyy'),
                moment(p.items[0].startDateTime).format('HH:mm A'),
                moment(p.items[0].endDateTime).format('HH:mm A'),
                this.getBillingDuration(p.items[0].startDateTime, p.items[0].endDateTime),
                p.items[0].errandMiles,
                p.items[0].billingCode,
            ];
            dataSet.push(firstRow);
            for (var i=0; i<p.items.length; i++) {
                const row = [
                    '',
                    '',
                    p.items[i].employeeId,
                    p.items[i].employeeLastName + ', ' + p.items[i].employeeFirstName,
                    moment(p.items[i].startDateTime).format('MM/DD/yyyy'),
                    moment(p.items[i].startDateTime).format('HH:mm A'),
                    moment(p.items[i].endDateTime).format('HH:mm A'),
                    this.getBillingDuration(p.items[i].startDateTime, p.items[i].endDateTime),
                    p.items[i].errandMiles,
                    p.items[i].billingCode,    
                ];
                dataSet.push(row);
            }
        });
        return dataSet;
    }

    getBillingDuration(startDateTime, endDateTime) {
        let actualDurationInMinutes = (new Date(endDateTime) - new Date(startDateTime)) / 1000 / 60;
        let billingDurationInMinutes = Math.round(actualDurationInMinutes / 15) * 15;
        let billingDurationInTicks = billingDurationInMinutes * 60 * 1000;
        return humanizeDuration(billingDurationInTicks, {units: ["h", "m"], round:true})
    }
}

export default PatientBillingReportExportBuilder;
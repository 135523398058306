import React from 'react';
import {
    CCard,
    CCardHeader,
    CCardBody,
    CCardFooter,
    CContainer,
    CRow,
    CCol,
    CSpinner,
} from '@coreui/react'
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
    headerClassName: PropTypes.string,
    onApiResponse: PropTypes.func,
    onError: PropTypes.func,
}

class CardComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };

        // these default settings can be overridden by the base class
        this.cardTitle = '';
        this.showHeader = true;
        this.showFooter = true;
    }

    componentDidMount() {
        this.initialize();
    }

    // virtual methods
    initialize() {
        // called on componentDidMount. child class should implement if needed.
    }

    isLoading() {
        return this.state.loading;
    }

    startLoading(message) {
        if (!message) message = "Loading...";
        return this.setState({loading: true, loadingMessage: message});
    }

    stopLoading() {
        this.setState({loading: false});
    }    

    renderLoading(loadingMessage) {
        return (
            <CContainer>
                <CRow className="justify-content-center mb-1">
                    <CCol>
                        <CSpinner component="span" size="lg" aria-hidden="true"/>
                    </CCol>
                </CRow>
                <CRow className="justify-content-center">
                    <CCol>
                        <p>{loadingMessage ? loadingMessage : this.state.loadingMessage}</p>
                    </CCol>
                </CRow>
            </CContainer>      
        );
    }

    renderHeaderContent() {
        return (
            <>
                {this.cardTitle}
            </>
        )
    }

    renderFooterContent() {
        return (
            <></>
        )
    }

    renderBodyContent() {
        return (
            <></>
        )
    }

    render() {
        return (
            <CCard color={this.props.color} className={this.props.className}>
                {
                    this.showHeader ? (
                        <CCardHeader className={this.props.headerClassName}>
                            {this.renderHeaderContent()}
                        </CCardHeader>
                    ) : ''
                }
                <CCardBody>
                    {
                        this.isLoading() ? this.renderLoading() : this.renderBodyContent()
                    }
                </CCardBody>
                {
                    this.showFooter ? (
                        <CCardFooter>
                            {this.renderFooterContent()}
                        </CCardFooter>
                    ) : ''
                }
            </CCard>
        )
    }
}

CardComponent.propTypes = propTypes;

export default CardComponent;

import ProviderBase from './ProviderBase';

const   BaseEndpoint = '/overtime-authorization',
        CancelEndpoint = '/overtime-authorization/{0}/cancel';

class OvertimeAuthorizationProvider extends ProviderBase {
    constructor() {
        super(BaseEndpoint);
    }

    getOne(id, includes) {
        return this._getOne(id, includes);
    }

    getAll(filter) {
        return this._getAll(filter);
    }

    post(data) {
        return this._post(data);
    }

    delete(id) {
        return this._delete(id);
    }

    cancel(id) {
        return this.api.patch(CancelEndpoint.format(id));
    }
}

export default OvertimeAuthorizationProvider;
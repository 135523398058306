import React from 'react'
import { Link } from 'react-router-dom'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CRow
} from '@coreui/react';
import cookie from 'react-cookies';
import { AuthProvider } from 'src/api';
import { PageComponent } from 'src/views/prototypes';
import LoginForm from './LoginForm';
import logo from '../../assets/logo.png';

const logoStyle = {
  height: '80px',
  textAlign: 'center',
  marginBottom: '15px'
}

class LoginPage extends PageComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      formDisabled: false,
      formProcessing: false,
    }

    // page size override
    this.pageWidth = 9;
    // className override
    this.className = "c-app c-default-layout flex-row align-items-center";

    this.authProvider = new AuthProvider();
  }

  initialize() {
    if (localStorage.getItem('email')) {
      this.setState({rememberMe: true, email: localStorage.getItem('email')});
    }

    return Promise.resolve();
  }

  handleSubmit(formState) {
    this.setState({formDisabled:true, formProcessing:true});
    this.authProvider.post({email: formState.email, password: formState.password})
    .then(response => {
      if (!response.ok) {
        if (response.status == 401) {
          this.showAlert("We could not log you in with that email and password. Please try again.", "danger", "cil-lock-locked");
        } else {
          this.showAlert("Hmm, something went wrong " + response.statusText, "danger", "cil-burn");
        }
      } else {
        return response;
      }
    })
    .then(response => response && response.json())
    .then(session => {
      if (session)
        this.completeLogin(formState, session);
    })
    .catch(error => {
      this.showAlert("An unexpected error occurred: " + error, "danger", "cil-burn");
    })
    .finally(() => {
      if (this._isMounted)
        this.setState({formDisabled: false, formProcessing: false});
    });
  }

  completeLogin(formState, session) {  
    // handle "remember me" checkbox
    if (formState.rememberMe) {
      // save email to local storage
      localStorage.setItem('email', this.state.email);
    } else {
      // remove email from local storage if it's there
      localStorage.removeItem('email');
    }
    var expire = new Date();
    expire.setTime(expire.getTime()+(30*60*1000));
    cookie.save('token', session.token, {path: '/', expires: expire});
    sessionStorage.setItem("session", JSON.stringify(session));
    var redirectOnLogin = '/tours';
    this.props.history.push(redirectOnLogin);
  }

  handleUnauthorized(response) {
    if (!response.ok) {
      if (response.status == 401) {
        this.showAlert("We could not log you in with that email and password. Please try again.", "danger", "cil-lock-locked");
      } else {
        this.showAlert("Hmm, something went wrong " + response.statusText, "danger", "cil-burn");
      }
    } else {
      return response;
    }
  }

  renderContent() {
    return (
      <>
        <CContainer>
          <CRow className="justify-content-center">
            <img src={logo} style={logoStyle} alt="Logo" />
          </CRow>
          <CRow className="justify-content-center">
            <h3>Telephony EVV Portal for JFST</h3>
          </CRow>                    
        </CContainer>
        <hr className="text-secondary"/>
        <CCardGroup>
          <CCard className="p-4">
            <CCardBody>
              <LoginForm disabled={this.state.formDisabled} processing={this.state.formProcessing} onSubmit={this.handleSubmit.bind(this)}/>
            </CCardBody>
          </CCard>
          <CCard className="text-white py-5 d-md-down-none" style={{ width: '44%', backgroundColor:'#2D99CD' }}>
            <CCardBody className="text-center">
              <div>
                <h2>Need Access?</h2>
                <p>
                  This internal application is for use by authorized JFST personnel only. Please check with your supervisor if you believe
                  you need access.
                </p>
              </div>
            </CCardBody>
          </CCard>
        </CCardGroup>
      </>
    )  
  }
}

export default LoginPage

import React from 'react';
import PropTypes from 'prop-types';
import {
    CFormGroup,
    CInputGroup,
    CInput,
    CLabel,
    CInputGroupPrepend,
    CInputGroupText,
    CRow,
    CCol,
    CButton,
    CInvalidFeedback,
    CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import passwordValidator from 'password-validator';
import { FormComponent } from '../prototypes';

class UserInvitationForm extends FormComponent {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            firstName: "",
            lastName: "",
            password: "",
            passwordConfirm: "",  
        }
    }

    getValidationSchema() {
        return [
            {
                field: 'firstName',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please enter your first name'
            },
            {
                field: 'lastName',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please enter your last name'
            },
            {
                field: 'password',
                method: this.passwordStrength,
                validWhen: true,
                message: 'Please choose a stronger password'
            },
            {
                field: 'passwordConfirm',
                method: 'isEmpty',
                validWhen: false,
                message: 'You must re-type your password here'
            },
            {
                field: 'passwordConfirm',
                method: this.passwordMatch, // custom function
                validWhen: true,
                message: 'The passwords do not match'
            }    
        ];
    }

    passwordMatch(confirmation, state) {
        return state.password === confirmation;
    };

    passwordStrength(password) {
        var schema = new passwordValidator();
        schema
        .is().min(8)
        .is().max(100)
        .has().uppercase()
        .has().lowercase()
        .has().digits()
        .has().not().spaces();
        return schema.validate(password);
    };

    renderContent(validationState) {
        return (
            <>
                <p className="text-muted text-center">
                    Welcome to the Noteable Telephony EVV app for Jewish Family Services of Tidewater. Please provide the following information 
                    so we can finish setting up your account.
                </p>
                <CFormGroup row>                                        
                    <CCol xs={6}>
                        <CLabel>First Name</CLabel>
                        <CInputGroup controlid="firstName">
                            <CInput type="text" id="firstName" placeholder="First" value={this.state.firstName} onChange={this.handleChange} invalid={validationState.firstName.isInvalid}/>
                            <CInvalidFeedback>{validationState.firstName.message}</CInvalidFeedback>
                        </CInputGroup>
                    </CCol>
                    <CCol xs={6}>
                        <CLabel>Last Name</CLabel>
                        <CInputGroup controlid="lastName">
                            <CInput type="text" id="lastName" placeholder="Last" value={this.state.lastName} onChange={this.handleChange} invalid={validationState.lastName.isInvalid}/>
                            <CInvalidFeedback>{validationState.lastName.message}</CInvalidFeedback>
                        </CInputGroup>
                    </CCol>
                </CFormGroup>
                <p className="text-muted text-center">
                    In order to protect your privacy, we insist on the use of
                    a strong password. Therefore, make sure the password you choose is at least
                    8 alpha-numeric characters long, contains a mixture of upper and lower case
                    and at least one number.
                </p>
                <CFormGroup>
                    <CInputGroup controlid="password" className="mb-3">
                        <CInputGroupPrepend>
                            <CInputGroupText>
                                <CIcon name="cil-lock-locked"/>
                            </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput type="password" id="password" placeholder="New Password" value={this.state.password} onChange={this.handleChange} invalid={validationState.password.isInvalid}/>
                        <CInvalidFeedback>{validationState.password.message}</CInvalidFeedback>
                    </CInputGroup>
                </CFormGroup>
                <CFormGroup>
                    <CInputGroup controlid="passwordConfirm" className="mb-3">
                        <CInputGroupPrepend>
                            <CInputGroupText>
                                <CIcon name="cil-lock-locked"/>
                            </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput type="password" id="passwordConfirm" placeholder="Re-enter New Password" value={this.state.passwordConfirm} onChange={this.handleChange} invalid={validationState.passwordConfirm.isInvalid}/>
                        <CInvalidFeedback>{validationState.passwordConfirm.message}</CInvalidFeedback>
                    </CInputGroup>
                </CFormGroup>
                <CRow>
                    <CCol xs="12">
                        <CButton type="submit" color="primary" block>
                        {
                            this.props.processing ? (
                                <React.Fragment>
                                    <CSpinner component="span" size="sm" aria-hidden="true" className="mr-1"/>
                                    Setting up your account...
                                </React.Fragment>
                            ) : "Confirm Account Setup"
                        }
                        </CButton>
                    </CCol>
                </CRow>
            </>
        );
    }
}

export default UserInvitationForm;
import ProviderBase from './ProviderBase';
import moment from 'moment';

const   BaseEndpoint = '/payroll-audit-report/{0}?fromDate={1}&toDate={2}';

class PayrollAuditReportProvider extends ProviderBase {
    constructor() {
        super(BaseEndpoint);
    }

    getReport(employeeId, startDate, endDate) {
        return this.api.get(BaseEndpoint.format(employeeId, moment(startDate).format('MM-DD-yyyy'), moment(endDate).format('MM-DD-yyyy')));
    }
}

export default PayrollAuditReportProvider;